export function generateTranscriptionId(length = 6) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function isValidTranscriptionId(transcriptionId: string): boolean {
  if (transcriptionId.length !== 6) {
    return false;
  }

  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  for (let i = 0; i < transcriptionId.length; i++) {
    if (!characters.includes(transcriptionId[i])) {
      return false;
    }
  }

  return true;
}
