import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import React from "react";

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function NoEventModal({ open, onClose }: Props) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth disableEscapeKeyDown>
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <Typography variant="h5">No Event in Progress</Typography>
        <Typography variant="body1" sx={{ mt: 2, verticalAlign: "center" }}>
          It looks like there is no event scheduled for right now. You will only
          be able to access the transcript once the event is in progress.
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 3 }}
        >
          <Button variant="text" color="primary" onClick={onClose}>
            OK
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
