import React from "react";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Chip, Typography } from "@mui/material";
import { useUsageControlContext } from "../../../../context/UsageControl.tsx";
import "./UsageLimit.css";
import { useTranscriptionContext } from "../../../../context/Transcription.tsx";

export default function UsageLimitTimer() {
  const { secondsLeft } = useUsageControlContext();
  const { transcriptionStatus } = useTranscriptionContext();
  return (
    <Chip
      className={
        secondsLeft > 0 &&
        secondsLeft / 60 <= 5 &&
        transcriptionStatus === "started"
          ? "pulse"
          : ""
      }
      variant="filled"
      color={secondsLeft / 60 <= 5 ? "error" : "secondary"}
      icon={<TimerOutlinedIcon />}
      label={
        <Typography variant="body1" fontWeight={500}>
          {Math.floor(secondsLeft / 60)
            .toString()
            .padStart(2, "0")}
          :{(secondsLeft % 60).toString().padStart(2, "0")}
        </Typography>
      }
    />
  );
}
