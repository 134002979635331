import React, { useEffect } from "react";
import RunMeetingHeaderSection from "./RunMeetingHeaderSection.tsx";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Divider,
  useTheme,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { RefreshOutlined } from "@mui/icons-material";

export default function HeaderAudioInputSection() {
  const theme = useTheme();
  const { setInputDevice, inputDevice, isTranscribing } =
    useTranscriptionContext();
  const [audioInputElement, setAudioInputElement] =
    React.useState<null | HTMLElement>(null);
  const audioInputOpen = Boolean(audioInputElement);
  const [inputDevices, setInputDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [volume, setVolume] = React.useState(0);

  useEffect(() => {
    let stream: MediaStream;
    let audioContext: AudioContext;
    let analyser: AnalyserNode;
    let source: MediaStreamAudioSourceNode;
    const setupVolumeChecker = async () => {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: inputDevice?.deviceId },
      });

      audioContext = new AudioContext();
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 128;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      const calculateVolume = async () => {
        // Get frequency data from analyser
        analyser.getByteFrequencyData(dataArray);

        // Calculate the average volume (energy) in the frequency data
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i];
        }

        // Normalize volume to a value between 0 and 100
        const average = sum / bufferLength;
        const normalizedVolume = Math.min((average / 255) * 120, 100);
        setVolume(normalizedVolume);
        // Continuously update the volume
        requestAnimationFrame(calculateVolume);
      };

      calculateVolume();
    };

    if (inputDevice) {
      setupVolumeChecker();
    }

    return () => {
      setVolume(0);
      if (stream) stream.getTracks().forEach((track) => track.stop());
      if (audioContext) audioContext.close();
      if (source) source.disconnect();
      if (analyser) analyser.disconnect();
    };
  }, [inputDevice]);

  const doOrganiseDevices = React.useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioInputDevices = devices.filter(
      (device) => device.kind === "audioinput"
    );
    setInputDevices(audioInputDevices);
    if (!inputDevice) {
      const selectedDevice = audioInputDevices.find(
        (inputDevice) => inputDevice.deviceId === "default"
      );
      if (selectedDevice) {
        setInputDevice(selectedDevice);
      }
    }
  }, [setInputDevice, inputDevice]);

  React.useEffect(() => {
    doOrganiseDevices();
  }, [doOrganiseDevices]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (!isTranscribing) {
      setAudioInputElement(event.currentTarget);
    }
  };

  const handleMenuItemClick = (option: string) => {
    if (!isTranscribing) {
      setInputDevice(
        inputDevices.find((inputDevice) => inputDevice.deviceId === option) ??
          null
      );
      setAudioInputElement(null);
    }
  };

  const handleClose = () => {
    setAudioInputElement(null);
  };

  const disabledStyle = isTranscribing ? { cursor: "not-allowed" } : {};

  return (
    <RunMeetingHeaderSection label="Audio Input">
      <Tooltip title={isTranscribing ? "Pause to enable" : ""}>
        <List
          sx={{
            padding: 0,
            maxWidth: "150px",
            ...disabledStyle,
          }}
        >
          <ListItemButton
            sx={{
              position: "relative",
              padding: 0.2,
              paddingLeft: 1.5,
              paddingRight: 1,
              borderRadius: "40px",
              overflow: "hidden",
            }}
            aria-expanded={audioInputOpen ? "true" : undefined}
            onClick={handleClickListItem}
            disabled={isTranscribing}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: `${volume < 5 ? 0 : volume}%`,
                height: "100%",
                backgroundColor: theme.palette.primary.main,
                opacity: 0.3,
                borderRadius: "0",
              }}
            ></div>
            <ListItemText
              primary={<Typography noWrap>{inputDevice?.label}</Typography>}
            />
            <ListItemIcon sx={{ minWidth: "unset" }}>
              <ExpandMoreOutlinedIcon />
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Tooltip>
      <Menu
        anchorEl={audioInputElement}
        open={audioInputOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {inputDevices.map((device) => (
          <MenuItem
            key={device.deviceId}
            selected={device.deviceId === inputDevice?.deviceId}
            onClick={() => handleMenuItemClick(device.deviceId)}
          >
            {device.label}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={doOrganiseDevices}>
          <ListItemIcon>
            <RefreshOutlined />
          </ListItemIcon>
          <ListItemText>Refresh Audio Devices</ListItemText>
        </MenuItem>
      </Menu>
    </RunMeetingHeaderSection>
  );
}
