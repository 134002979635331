import React from "react";
import { startAsyncInterval } from "../utils/misc.ts";

export enum InternetStrengthStatus {
  NONE = "NONE",
  SLOW = "SLOW",
  MEDIUM = "AVERAGE",
  FAST = "FAST",
}

const speedThresholds: Record<InternetStrengthStatus, number /* kb */> = {
  NONE: 0,
  SLOW: 500, // 0.5 MBps
  AVERAGE: 1000, // 1 MBps
  FAST: 2000, // 2 MBps
};

interface InternetStrengthlContextState {
  strength: InternetStrengthStatus;
}

type InternetStrengthProviderProps = React.PropsWithChildren;

/**
 * Calculates the download speed for a given file URL.
 *
 * This function fetches a file from the provided URL and calculates the download speed by measuring
 * the time taken to download the file and the size of the file. It returns the speed in KBps
 * along with a category (slow, medium, or fast).
 *
 * @param url - The URL of the file to download.
 * @returns An object containing the download speed (in KBps) and a speed category.
 * @throws Will throw an error if the file cannot be fetched or if the file size cannot be determined.
 */
export async function getDownloadSpeed(url: string): Promise<{
  speedCategory: InternetStrengthStatus;
  speedInKBps: string; // Rounded to 2 decimal places
}> {
  // Record the start time of the download

  // Fetch the file from the URL
  let response;
  try {
    response = await fetch(url, { cache: "no-store" });
  } catch (error) {
    console.error("Failed to fetch file:", error);
    return {
      speedCategory: InternetStrengthStatus.NONE,
      speedInKBps: "0",
    };
  }

  const startTime = performance.now();

  // Read the file as a stream to measure download progress
  const reader = response.body?.getReader();
  if (!reader) {
    console.error("Failed to get reader from response body");
    return {
      speedCategory: InternetStrengthStatus.NONE,
      speedInKBps: "0",
    };
  }

  let bytesRead = 0;
  let finish = false;
  while (!finish) {
    const { done, value } = await reader.read();
    if (done) {
      finish = true;
      break;
    }
    if (value) bytesRead += value.length;
  }

  const downloadTimeInSeconds = (performance.now() - startTime) / 1000;

  // Calculate download speed in bytes per second
  const speedInBytesPerSecond = bytesRead / downloadTimeInSeconds;

  // Convert speed to kilobytes per second (KBps)
  const speedInKBps = speedInBytesPerSecond / 1024;

  // Classify the speed into categories
  let speedCategory: InternetStrengthStatus = InternetStrengthStatus.SLOW;
  if (speedInKBps >= speedThresholds[InternetStrengthStatus.FAST]) {
    speedCategory = InternetStrengthStatus.FAST;
  } else if (speedInKBps >= speedThresholds[InternetStrengthStatus.MEDIUM]) {
    speedCategory = InternetStrengthStatus.MEDIUM;
  }

  // Return the results
  return {
    speedCategory,
    speedInKBps: speedInKBps.toFixed(2), // Round to 2 decimal places
  };
}

export const InternetStrengthContext = React.createContext<
  InternetStrengthlContextState | undefined
>(undefined);

export const InternetStrengthProvider: React.FC<
  InternetStrengthProviderProps
> = ({ children }) => {
  const [strength, setStrength] = React.useState<InternetStrengthStatus>(
    InternetStrengthStatus.MEDIUM
  );
  React.useEffect(() => {
    startAsyncInterval(async () => {
      const res = await getDownloadSpeed(
        `${process.env.REACT_APP_INTERNET_SPEED_TEST_URL}?t=${Date.now()}`
      );
      setStrength(res.speedCategory);
    }, 1_000 * 60 * 2); // run every 2 minutes
  }, []);
  return (
    <InternetStrengthContext.Provider
      value={{
        strength: strength,
      }}
    >
      {children}
    </InternetStrengthContext.Provider>
  );
};

export const useInternetStrengthContext = () => {
  const context = React.useContext(InternetStrengthContext);
  if (context === undefined)
    throw new Error(
      "useInternetStrengthContext must be used within a InternetStrengthProvider"
    );

  return context;
};
