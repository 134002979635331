import {
  Box,
  Button,
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";

type Props = {
  onCancel: () => void;
  onDone: () => void;
};

export default function CustomPhrasesModal({ onCancel, onDone }: Props) {
  const { customPhrases, setCustomPhrases } = useTranscriptionContext();
  const theme = useTheme();
  const [phrases, setPhrases] = React.useState<string>(customPhrases.join(","));
  const phrasesArray = useMemo(() => {
    if (!phrases) return [];
    return phrases
      .split(",")
      .map((p) => p.trim())
      .filter((p) => p.length > 0);
  }, [phrases]);

  const handlePhrasesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPhrases(e.target.value);
  };

  const handleCancelClick = () => {
    onCancel();
  };

  const handleDoneClick = () => {
    setCustomPhrases(phrasesArray);
    onDone();
  };
  return (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 300,
        width: "500px",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Typography id="modal-modal-title" variant="h5">
          Add custom phrases
        </Typography>

        <FormControl fullWidth sx={{ mt: 3 }}>
          <TextField
            multiline
            minRows={3}
            value={phrases}
            onChange={handlePhrasesChange}
            label="Enter your phrases separated by a comma"
            placeholder="Enter your custom phrases..."
            variant="outlined"
          />
        </FormControl>

        <Stack sx={{ mt: 3 }} direction="row" justifyContent="space-between">
          <Button
            onClick={handleCancelClick}
            variant="text"
            sx={{ color: "grey" }}
          >
            Cancel
          </Button>
          <Button onClick={handleDoneClick} variant="contained" color="primary">
            Done
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
}
