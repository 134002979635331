import React from "react";
import { InternetStrengthStatus } from "../context/InternetStrength.tsx";

type Props = {
  strength: InternetStrengthStatus;
};

export default function InternetSignal({ strength }: Props) {
  const signalColorForStrengthLevel = (
    strengthLine: InternetStrengthStatus
  ) => {
    if (strength === InternetStrengthStatus.NONE) {
      return "grey";
    } else if (strength === InternetStrengthStatus.FAST) {
      return "white";
    } else if (strength === InternetStrengthStatus.SLOW) {
      if (strengthLine === InternetStrengthStatus.SLOW) {
        return "white";
      } else {
        return "grey";
      }
    } else if (strength === InternetStrengthStatus.MEDIUM) {
      if (
        strengthLine === InternetStrengthStatus.SLOW ||
        strengthLine === InternetStrengthStatus.MEDIUM
      ) {
        return "white";
      } else {
        return "grey";
      }
    }
  };

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 12.5C11.1191 12.5 9.99997 13.6191 9.99997 15C9.99997 16.3809 11.1191 17.5 12.5 17.5C13.8808 17.5 15 16.3809 15 15C15 13.6191 13.8808 12.5 12.5 12.5Z"
        fill={signalColorForStrengthLevel(InternetStrengthStatus.SLOW)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4168 9.23477C15.9144 5.25313 9.08044 5.25742 4.58317 9.23477C4.31364 9.47305 4.30504 9.88672 4.5609 10.1391L5.90622 11.4668C6.14059 11.698 6.51794 11.7137 6.76754 11.498C10.0468 8.66328 14.9605 8.66953 18.232 11.498C18.4816 11.7137 18.859 11.6984 19.0933 11.4668L20.4386 10.1391C20.6949 9.88672 20.6859 9.47266 20.4168 9.23477Z"
        fill={signalColorForStrengthLevel(InternetStrengthStatus.MEDIUM)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8011 4.8C17.8804 -1.60117 7.11676 -1.59883 0.198795 4.8C-0.0613614 5.04062 -0.0664395 5.44805 0.185123 5.69766L1.52262 7.02461C1.76247 7.26289 2.1484 7.26797 2.39762 7.03945C8.09762 1.81758 16.9015 1.81641 22.6027 7.03945C22.8519 7.26797 23.2379 7.2625 23.4777 7.02461L24.8152 5.69766C25.0664 5.44805 25.0613 5.04062 24.8011 4.8Z"
        fill={signalColorForStrengthLevel(InternetStrengthStatus.FAST)}
      />
    </svg>
  );
}
