import { RoomEvent } from "../models/Event";

export async function getEventsByRoomId(roomId: string): Promise<RoomEvent[]> {
  const res = await fetch(
    `${process.env.REACT_APP_SOCKET_REST_URL}/getAllEvents?room_id=${roomId}`
  );
  if (res.status !== 200) {
    const errorMessage = await res.text();
    throw new Error(errorMessage);
  }
  const result = await res.json();
  return result as RoomEvent[];
}
