/* eslint-disable */
import {
  Alert,
  Button,
  Dialog,
  Fade,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";

type Props = {
  open: boolean;
  onClose?: () => void;
};

export default function UsageLimitReachedModal({ open, onClose }: Props) {
  const theme = useTheme();
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Fade ref={ref} {...props} />;
  });

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      TransitionComponent={Transition}
      transitionDuration={5000}
      TransitionProps={{
        enter: true,
        exit: false,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: "2em 8em",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Stack direction="column" spacing={4} alignItems="center">
          <Alert
            icon={false}
            variant="standard"
            severity="error"
            sx={{ justifyContent: "center" }}
          >
            <Stack direction="column" spacing={1} alignItems="center">
              <Typography variant="h5" color="#b63737">
                Usage Limit Reached
              </Typography>
              <Typography textAlign="center" variant="body1">
                You have reached the daily presentation usage allowance on the
                demo platform
              </Typography>
            </Stack>
          </Alert>

          <Stack direction="column" spacing={2} alignItems="center">
            <Typography variant="h4" textAlign="center">
              Want unlimited access?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.open(
                  process.env.REACT_APP_CONTACT_US_URL,
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            >
              Contact Us
            </Button>
            <Link
              underline="none"
              href={process.env.REACT_APP_WEBSITE_URL}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "grey", textTransform: "none" }}
            >
              <Stack direction="row" alignItems="center">
                <Typography>Visit our website</Typography>
                <ArrowOutwardOutlinedIcon fontSize="small" />
              </Stack>
            </Link>
          </Stack>
        </Stack>
      </Paper>
    </Dialog>
  );
}
