import React from "react";
import RunMeetingHeaderSection from "./RunMeetingHeaderSection.tsx";
import {
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Divider,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { useTheme } from "@mui/material/styles";

export const languageOptions = new Map<string, string>([
  ["en-AU", "English (AU)"],
  ["en-US", "English (US)"],
  ["zh-CN", "Mandarin"],
  ["ar-SA", "Arabic"],
  ["zh-HK", "Cantonese"],
  ["vi-VN", "Vietnamese"],
  ["it-IT", "Italian"],
  ["el-GR", "Greek"],
  ["hi-IN", "Hindi"],
  ["es-ES", "Spanish"],
  ["pa-IN", "Punjabi"],
  ["af-ZA", "Afrikaans"],
  ["sq-AL", "Albanian"],
  ["am-ET", "Amharic"],
  ["hy-AM", "Armenian"],
  ["az-AZ", "Azerbaijani"],
  ["eu-ES", "Basque"],
  ["bn-IN", "Bengali"],
  ["bs-BA", "Bosnian"],
  ["bg-BG", "Bulgarian"],
  ["my-MM", "Burmese"],
  ["ca-ES", "Catalan"],
  ["hr-HR", "Croatian"],
  ["cs-CZ", "Czech"],
  ["da-DK", "Danish"],
  ["nl-NL", "Dutch"],
  ["et-EE", "Estonian"],
  ["fi-FI", "Finnish"],
  ["fil-PH", "Filipino"],
  ["fr-FR", "French"],
  ["gl-ES", "Galician"],
  ["ka-GE", "Georgian"],
  ["de-DE", "German"],
  ["el-GR", "Greek"],
  ["gu-IN", "Gujarati"],
  ["he-IL", "Hebrew"],
  ["hu-HU", "Hungarian"],
  ["is-IS", "Icelandic"],
  ["id-ID", "Indonesian"],
  ["ga-IE", "Irish"],
  ["ja-JP", "Japanese"],
  ["jv-ID", "Javanese"],
  ["kn-IN", "Kannada"],
  ["kk-KZ", "Kazakh"],
  ["km-KH", "Khmer"],
  ["ko-KR", "Korean"],
  ["lo-LA", "Lao"],
  ["lv-LV", "Latvian"],
  ["lt-LT", "Lithuanian"],
  ["mk-MK", "Macedonian"],
  ["ms-MY", "Malay"],
  ["ml-IN", "Malayalam"],
  ["mt-MT", "Maltese"],
  ["mr-IN", "Marathi"],
  ["mn-MN", "Mongolian"],
  ["ne-NP", "Nepali"],
  ["nb-NO", "Norwegian"],
  ["ps-AF", "Pashto"],
  ["fa-IR", "Persian"],
  ["pl-PL", "Polish"],
  ["pt-BR", "Portuguese"],
  ["ro-RO", "Romanian"],
  ["ru-RU", "Russian"],
  ["sr-RS", "Serbian"],
  ["si-LK", "Sinhala"],
  ["sk-SK", "Slovak"],
  ["sl-SI", "Slovenian"],
  ["so-SO", "Somali"],
  ["sw-KE", "Swahili"],
  ["sv-SE", "Swedish"],
  ["ta-IN", "Tamil"],
  ["te-IN", "Telugu"],
  ["th-TH", "Thai"],
  ["tr-TR", "Turkish"],
  ["uk-UA", "Ukrainian"],
  ["ur-IN", "Urdu"],
  ["uz-UZ", "Uzbek"],
  ["cy-GB", "Welsh"],
  ["zu-ZA", "Zulu"],
]);
export default function HeaderLanguageSection() {
  const {
    setSourceLanguage,
    setTargetLanguage,
    languageSettings,
    isTranscribing,
    currentEvent,
  } = useTranscriptionContext();
  const [fromLanguageElement, setFromLanguageElement] =
    React.useState<null | HTMLElement>(null);
  const fromLanguageOpen = Boolean(fromLanguageElement);
  const [toLanguageElement, setToLanguageElement] =
    React.useState<null | HTMLElement>(null);
  const toLanguageOpen = Boolean(toLanguageElement);
  const theme = useTheme();
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
  });

  const handleClickFromLanguageListItem = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (!isTranscribing) {
      setFromLanguageElement(event.currentTarget);
    }
  };

  const handleFromLanguageMenuItemClick = (option: string) => {
    if (!isTranscribing) {
      setSourceLanguage(option);
      setFromLanguageElement(null);
    }
  };

  const handleClose = () => {
    setFromLanguageElement(null);
  };

  const handleClickToLanguageListItem = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (!isTranscribing) {
      setToLanguageElement(event.currentTarget);
    }
  };

  const handleToLanguageMenuItemClick = (option: string) => {
    if (!isTranscribing) {
      setTargetLanguage(option);
      setToLanguageElement(null);
    }
  };

  const handleCloseToLanguage = () => {
    setToLanguageElement(null);
  };

  const handleSwapLanguages = () => {
    if (!isTranscribing) {
      const temp = languageSettings.source;
      setSourceLanguage(languageSettings.target);
      setTargetLanguage(temp);
    }
  };

  const disabledStyle = isTranscribing ? { cursor: "not-allowed" } : {};

  const getAllLanguageOptions = (
    availableLanguages: string[],
    currentLanguage: string
  ) => {
    // Convert available languages array to Map with index for sorting
    const availableMap = new Map(
      availableLanguages.map((lang, index) => [lang, index])
    );

    // Get all languages from languageOptions map
    const allOptions = Array.from(languageOptions.keys()).map((langCode) => ({
      code: langCode,
      name: languageOptions.get(langCode) || "",
      available: availableMap.has(langCode),
      selected: langCode === currentLanguage,
      sortIndex: availableMap.get(langCode) ?? Number.MAX_SAFE_INTEGER,
    }));

    // Sort: available languages first (in original order), then remaining languages
    return allOptions.sort((a, b) => {
      if (a.available && !b.available) return -1;
      if (!a.available && b.available) return 1;
      if (a.available && b.available) {
        return a.sortIndex - b.sortIndex;
      }
      return a.name.localeCompare(b.name); // alphabetical order for unavailable languages
    });
  };

  const handleUnavailableLanguageClick = (languageName: string) => {
    setSnackbar({
      open: true,
      message: `Support for ${languageName} is coming soon!`,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const renderMenuItems = (
    options: ReturnType<typeof getAllLanguageOptions>,
    handleClick: (code: string) => void
  ) => {
    let lastWasAvailable = true;
    return options
      .map(({ code, name, available, selected }, index) => {
        const items: JSX.Element[] = [];

        // Add divider when transitioning from available to unavailable
        if (lastWasAvailable && !available) {
          items.push(<Divider key={`divider-${index}`} />);
        }
        lastWasAvailable = available;

        items.push(
          <MenuItem
            key={code}
            selected={selected}
            onClick={() =>
              available
                ? handleClick(code)
                : handleUnavailableLanguageClick(name)
            }
            disabled={isTranscribing}
          >
            {name}
          </MenuItem>
        );

        return items;
      })
      .flat();
  };

  return (
    <RunMeetingHeaderSection label="Language">
      <Stack direction="row" alignItems="center" spacing={0}>
        <Tooltip title={isTranscribing ? "Pause to enable" : ""}>
          <List sx={{ padding: 0, width: "90px", ...disabledStyle }}>
            <ListItemButton
              sx={{
                padding: 0.2,
                paddingLeft: 1.5,
                paddingRight: 1.5,
                borderRadius: "40px",
                justifyContent: "flex-end",
                border: `1px solid ${theme.palette.divider}`,
                "&:hover": {
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
              aria-expanded={fromLanguageOpen ? "true" : undefined}
              onClick={handleClickFromLanguageListItem}
              disabled={isTranscribing}
            >
              <ListItemText
                primary={
                  <Typography noWrap>
                    {languageOptions.get(languageSettings.source)}
                  </Typography>
                }
                sx={{ textAlign: "right" }}
              />
            </ListItemButton>
          </List>
        </Tooltip>
        <Tooltip title={isTranscribing ? "Pause to enable" : ""}>
          <IconButton
            onClick={handleSwapLanguages}
            size="small"
            disabled={isTranscribing}
            sx={disabledStyle}
          >
            <SwapHorizIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={isTranscribing ? "Pause to enable" : ""}>
          <List
            sx={{
              padding: 0,
              width: "90px",
              ...disabledStyle,
            }}
          >
            <ListItemButton
              sx={{
                padding: 0.2,
                paddingLeft: 1.5,
                paddingRight: 1.5,
                borderRadius: "40px",
                border: `1px solid ${theme.palette.divider}`,
                "&:hover": {
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                },
              }}
              aria-expanded={toLanguageOpen ? "true" : undefined}
              onClick={handleClickToLanguageListItem}
              disabled={isTranscribing}
            >
              <ListItemText
                primary={
                  <Typography noWrap>
                    {languageOptions.get(languageSettings.target)}
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        </Tooltip>
      </Stack>
      <Menu
        anchorEl={fromLanguageElement}
        open={fromLanguageOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {renderMenuItems(
          getAllLanguageOptions(
            currentEvent?.source_language.split(",") || [],
            languageSettings.source
          ),
          handleFromLanguageMenuItemClick
        )}
      </Menu>
      <Menu
        anchorEl={toLanguageElement}
        open={toLanguageOpen}
        onClose={handleCloseToLanguage}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {renderMenuItems(
          getAllLanguageOptions(
            currentEvent?.target_languages || [],
            languageSettings.target
          ),
          handleToLanguageMenuItemClick
        )}
      </Menu>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="info">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </RunMeetingHeaderSection>
  );
}
