export interface CreateEventResponse {
  room: {
    uuid: string;
    name: string;
    created_at: string;
    modified_at: string;
  };
}

export async function createV3Event(): Promise<CreateEventResponse> {
  // const baseUrl = process.env.REACT_APP_SOCKET_REST_URL;
  // console.log("Base URL:", baseUrl);

  // // Remove any trailing slashes and ensure https://
  // let cleanBaseUrl = baseUrl
  //   ?.replace(/\/+$/, "")
  //   .replace(/^http:\/\//, "https://");

  // if (!cleanBaseUrl?.startsWith("https://")) {
  //   cleanBaseUrl = `https://${cleanBaseUrl}`;
  // }

  // const fullUrl = `${cleanBaseUrl}/create-event-v3/`;
  // const fullUrl = `${process.env.REACT_APP_SUNFLOWER_BACKEND_URL}/create_event_v3/`;

  // prettier-ignore
  const access_token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkJFeEFWUUtrTVBSWkV3RWdicTE5UCJ9.eyJpc3MiOiJodHRwczovL3N1bmZsb3dlci1haS5hdS5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjZlZTc0NGEzNDQyMGExY2M1NDNiMTkxIiwiYXVkIjoiaHR0cHM6Ly9zdW5mbG93ZXItYWktcmVzdC5henVyZXdlYnNpdGVzLm5ldC9hcGkvdjEvIiwiaWF0IjoxNzM0MDY1OTAzLCJleHAiOjE3MzQxNTIzMDMsImd0eSI6InBhc3N3b3JkIiwiYXpwIjoiNFp2NWE1Vzh2QmJNNFdLaDB4WFM1dXNqbUJJRWdERDQifQ.FA2auVbxy4a-IDGjE6iAOyssBb-rZs6Z64l_Xw8MUkYxB1PeG_SXhXCQBopEb-DCTjGYGNPpa-c7w7FBIuN-SsFKt4wkCZ34uPFRnOAMlGVeXAGnlCgCC5Z1vDim-1uDQgK5EwbBfzTHruwWWLeJOOtzbuGnQfcqS8nZF8J8XRGUhNtxicHDzvdSmoybLjJAhaAo5qlKBz_s-X6i_-ewUDZtkxDwirCQFfGk0Y-WeBzBgfN_w22c0oamA33Avb1EceQFeOGPq-VKWNIojzSNhzAAq8RQLAq6iARsePMzGYH8F02z9WECixJtxwsC39FDrqvuPi2leaIU0njALzm7Hw";
  const fullUrl =
    "https://sunflower-ai-rest.azurewebsites.net/api/v1/rooms/create-event-v3/";
  console.log("Full URL:", fullUrl);

  const res = await fetch(fullUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

  if (res.status !== 200 && res.status !== 201) {
    const errorMessage = await res.text();
    throw new Error(errorMessage);
  }

  const result = await res.json();
  return result as CreateEventResponse;
}
