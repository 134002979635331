/**
 * Waits for a specified amount of time.
 */
export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function startAsyncInterval(
  asyncFn: () => Promise<void>,
  interval: number
): void {
  const run = async () => {
    await asyncFn(); // Wait for the async function to complete
    setTimeout(run, interval); // Schedule the next run after the interval
  };

  run(); // Start the first iteration
}
