/* eslint-disable */
import React from "react";
import RunMeetingHeaderSection from "./RunMeetingHeaderSection.tsx";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  TranscriptionPositionType,
  useTranscriptionDisplay,
} from "../../../context/TranscriptionDisplay.tsx";

type CaptionPositionTypeOption = {
  label: string;
  value: TranscriptionPositionType;
};

const options: Array<CaptionPositionTypeOption> = [
  {
    label: "Bottom",
    value: "fixed-bottom",
  },
  {
    label: "Top",
    value: "fixed-top",
  },
  {
    label: "Floating",
    value: "floating",
  },
];
export default function HeaderCaptionPositionSection() {
  const {
    setTranscriptionPositionTypeAndUpdateBackground,
    transcriptionPositionType,
    transcriptionType,
  } = useTranscriptionDisplay();

  const [captionPositionTypeElement, setCaptionPositionTypeElement] =
    React.useState<null | HTMLElement>(null);
  const captionPositionTypeOpen = Boolean(captionPositionTypeElement);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (transcriptionType !== "full-screen") {
      setCaptionPositionTypeElement(event.currentTarget);
    }
  };

  const handleMenuItemClick = (option: CaptionPositionTypeOption) => {
    if (transcriptionType !== "full-screen") {
      setTranscriptionPositionTypeAndUpdateBackground(option.value);
      setCaptionPositionTypeElement(null);
    }
  };

  const handleClose = () => {
    setCaptionPositionTypeElement(null);
  };

  const isDisabled = transcriptionType === "full-screen";

  return (
    <RunMeetingHeaderSection label="Caption Position">
      <>
        <Tooltip title={isDisabled ? "Not available in full-screen mode" : ""}>
          <span>
            <List
              sx={{
                padding: 0,
                opacity: isDisabled ? 0.5 : 1,
                pointerEvents: isDisabled ? "none" : "auto",
              }}
            >
              <ListItemButton
                sx={{
                  padding: 0.2,
                  paddingLeft: 1.5,
                  paddingRight: 1,
                  borderRadius: "40px",
                }}
                aria-expanded={captionPositionTypeOpen ? "true" : undefined}
                onClick={handleClickListItem}
                disabled={isDisabled}
              >
                <ListItemText
                  primary={
                    options.find((o) => o.value === transcriptionPositionType)!
                      .label
                  }
                />
                <ListItemIcon sx={{ minWidth: "unset" }}>
                  <ExpandMoreOutlinedIcon />
                </ListItemIcon>
              </ListItemButton>
            </List>
          </span>
        </Tooltip>
        <Menu
          anchorEl={captionPositionTypeElement}
          open={captionPositionTypeOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === transcriptionPositionType}
              onClick={() => handleMenuItemClick(option)}
              disabled={isDisabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    </RunMeetingHeaderSection>
  );
}
