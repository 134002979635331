import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createV3Event } from "../../services/CreateV3Event.ts";
import { Snackbar, Alert } from "@mui/material";

export default function EmptyMeeting() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleRoomLogic = async () => {
      // Check localStorage for existing roomId
      const existingRoomId = localStorage.getItem("roomId");

      if (existingRoomId) {
        navigate(`/event/${existingRoomId}`);
        return;
      }

      // Create new room if no existing roomId
      try {
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Timeout")), 10000)
        );

        const response = await Promise.race([createV3Event(), timeoutPromise]);
        console.log("handleRoomLogic response", response);
        localStorage.setItem("roomId", response.room.name);
        navigate(`/event/${response.room.name}`);
      } catch (error) {
        console.error("Failed to create event:", error);
        setError(true);
      }
    };

    handleRoomLogic();
  }, [navigate]);

  return (
    <Snackbar
      open={error}
      autoHideDuration={3000}
      onClose={() => setError(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ width: "500px" }}
    >
      <Alert
        severity="error"
        sx={{
          width: "500px",
          margin: "0 auto",
          "& .MuiAlert-icon": {
            marginTop: "auto",
            marginBottom: "auto",
          },
          "& .MuiAlert-message": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        Our servers are experiencing high traffic. Please try again later ʕ •ᴥ•ʔ
      </Alert>
    </Snackbar>
  );
}
