import React from "react";
import "./styles/App.css";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

function App() {
  return (
    <Box
      className="main__app"
      sx={{
        width: "100vw",
      }}
    >
      <Outlet />
    </Box>
  );
}

export default App;
