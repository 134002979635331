/* eslint-disable */
import React from "react";
import RunMeetingHeaderSection from "./RunMeetingHeaderSection.tsx";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useTranscriptionDisplay } from "../../../context/TranscriptionDisplay.tsx";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { Tooltip } from "@mui/material";

type TranscriptionTypeOption = {
  label: string;
  value: TranscriptionType;
};
export type TranscriptionType = "caption-only" | "full-screen";
const options: Array<TranscriptionTypeOption> = [
  {
    label: "Caption Only",
    value: "caption-only",
  },
  {
    label: "Full Transcript",
    value: "full-screen",
  },
];
// type TranscriptionPositionOption = {
//     label: string,
//     value: TranscriptionPosition
// }
// export type TranscriptionPosition = "top" | "bottom"
// const positionOptions: Array<TranscriptionPositionOption> = [{
//     label: "Top",
//     value: "top"
// },{
//     label: "Bottom",
//     value: "bottom"
// }]
export default function HeaderTranscriptionSection() {
  const { setTranscriptionType, transcriptionType } = useTranscriptionDisplay();
  const { transcriptionStatus } = useTranscriptionContext();

  const [transcriptionTypeElement, setTranscriptionTypeElement] =
    React.useState<null | HTMLElement>(null);
  const transcriptTypeOpen = Boolean(transcriptionTypeElement);
  //const [ transcriptionPositionElement, setTranscriptionPositionElement ] = React.useState<null | HTMLElement>(null);
  //const transcriptPositionOpen = Boolean(transcriptionPositionElement);
  const isDisabled = transcriptionStatus === "started";

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (!isDisabled) {
      setTranscriptionTypeElement(event.currentTarget);
    }
  };

  const handleMenuItemClick = (option: TranscriptionTypeOption) => {
    if (!isDisabled) {
      setTranscriptionType(option.value);
      setTranscriptionTypeElement(null);
    }
  };

  const handleClose = () => {
    setTranscriptionTypeElement(null);
  };

  // const handleClickPositionItem = (event: React.MouseEvent<HTMLElement>) => {
  //     setTranscriptionPositionElement(event.currentTarget);
  // };

  // const handlePositionMenuItemClick = (
  //     option: TranscriptionPositionOption
  // ) => {
  //     setTranscriptionPosition(option.value)
  //     setTranscriptionPositionElement(null);
  // };

  // const handleClosePosition = () => {
  //     setTranscriptionPositionElement(null);
  // };
  return (
    <RunMeetingHeaderSection label="Display">
      <>
        <Tooltip
          title={
            isDisabled ? "Not available while captioning is in progress" : ""
          }
        >
          <span>
            <List
              sx={{
                padding: 0,
                opacity: isDisabled ? 0.5 : 1,
                pointerEvents: isDisabled ? "none" : "auto",
              }}
            >
              <ListItemButton
                sx={{
                  padding: 0.2,
                  paddingLeft: 1.5,
                  paddingRight: 1,
                  borderRadius: "40px",
                }}
                aria-expanded={transcriptTypeOpen ? "true" : undefined}
                onClick={handleClickListItem}
                disabled={isDisabled}
              >
                <ListItemText
                  primary={
                    options.find((o) => o.value === transcriptionType)!.label
                  }
                />
                <ListItemIcon sx={{ minWidth: "unset" }}>
                  <ExpandMoreOutlinedIcon />
                </ListItemIcon>
              </ListItemButton>
            </List>
          </span>
        </Tooltip>
        <Menu
          anchorEl={transcriptionTypeElement}
          open={transcriptTypeOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === transcriptionType}
              onClick={() => handleMenuItemClick(option)}
              disabled={isDisabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
      {/* <>
            <List
                sx={{
                    padding: 0
                }}
            >
                <ListItemButton
                     sx={{ padding: 0.2, paddingLeft: 1.5, paddingRight: 1, borderRadius: "40px"}}
                    aria-expanded={transcriptPositionOpen ? 'true' : undefined}
                    onClick={handleClickPositionItem}
                >
                    <ListItemText primary={positionOptions.find(po => po.value === transcriptionPosition)!.label} />
                    <ListItemIcon sx={{ minWidth: 'unset'}}><ExpandMoreOutlinedIcon /></ListItemIcon>
                </ListItemButton>
            </List>
            <Menu
                anchorEl={transcriptionPositionElement}
                open={transcriptPositionOpen}
                onClose={handleClosePosition}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {positionOptions.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === transcriptionPosition}
                        onClick={() => handlePositionMenuItemClick(option)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </> */}
    </RunMeetingHeaderSection>
  );
}
