import { Stack, Typography, useTheme } from "@mui/material";
import React, { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  label: string;
}>;

export default function RunMeetingHeaderSection({ label, children }: Props) {
  const theme = useTheme();
  return (
    <Stack alignItems="center" gap={0.5}>
      <Typography
        sx={{
          color: theme.palette.grey[600],
          fontSize: "14px",
        }}
      >
        {label || "\u00A0"}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        {children}
      </Stack>
    </Stack>
  );
}
