import React from "react";
import { useNavigate } from "react-router-dom";
import AuthCode from "react-auth-code-input";
import { getSubscriberTranscript } from "../../../services/SubscriberTranscript.ts";
import { Box, Typography } from "@mui/material";

export default function SubscriberCodeEmpty() {
  const navigate = useNavigate();
  const [code, setCode] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    const doCodeCheck = async () => {
      try {
        await getSubscriberTranscript(code);
        navigate(`/transcript/${code}`);
      } catch (error) {
        setError(error.message);
      }
    };
    if (code.length === 6) {
      doCodeCheck();
    }
  }, [code, navigate]);

  const handleCodeChange = (code: string) => {
    setError("");
    setCode(code.toUpperCase());
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        //backgroundColor: transcriptionDisplayTheme.backgroundColor,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          marginTop: "-50px",
        }}
      >
        <Box
          component="img"
          src={"/sunflower_light.svg"}
          alt={"Sunflower logo"}
          sx={{
            height: "40px",
            marginBottom: "40px",
          }}
        />
        <Typography>Scan the event QR Code to get started</Typography>
        <Typography fontSize="small">OR</Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap="20px"
        >
          <Typography textAlign="center">Enter the event code</Typography>
          <AuthCode
            inputClassName="code-input"
            onChange={handleCodeChange}
            containerClassName="code-container"
          />
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
