import {
  Autocomplete,
  Box,
  Modal,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import { languageOptions } from "../../RunMeeting/components/HeaderTranscriptionLanguage.tsx";
import { useTranscriptionContext } from "../../../context/Transcription.tsx";
import { isDesktop } from "react-device-detect";

type Props = {
  open: boolean;
  onLanguageSelect: (lang: string) => void;
  selectedLanguage?: string;
  onClose: () => void;
};

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "90vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  //border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SubscriberLanguageModal({
  open,
  selectedLanguage,
  onLanguageSelect,
  onClose,
}: Props) {
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
  });
  const { currentEvent } = useTranscriptionContext();

  const allLanguageOptions = React.useMemo(() => {
    if (!currentEvent) return [];

    // Convert available languages to Set for faster lookup
    const availableSet = new Set(currentEvent.target_languages);

    // Get all languages, marking which are available
    const options = Array.from(languageOptions.entries()).map(
      ([code, name]) => ({
        label: name,
        id: code,
        available: availableSet.has(code),
      })
    );

    // Sort: available languages first (in original order), then remaining
    return options.sort((a, b) => {
      if (a.available && !b.available) return -1;
      if (!a.available && b.available) return 1;
      if (a.available && b.available) {
        return (
          currentEvent.target_languages.indexOf(a.id) -
          currentEvent.target_languages.indexOf(b.id)
        );
      }
      return a.label.localeCompare(b.label);
    });
  }, [currentEvent]);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleLanguageSelect = React.useCallback(
    (newValue: { label: string; id: string; available: boolean } | null) => {
      if (!newValue) return;

      if (newValue.available) {
        onLanguageSelect(newValue.id);
        localStorage.setItem("selectedLanguage", JSON.stringify(newValue));
      } else {
        setSnackbar({
          open: true,
          message: `Support for ${newValue.label} is coming soon!`,
        });
      }
    },
    [setSnackbar, onLanguageSelect]
  );

  React.useEffect(() => {
    if (!currentEvent) return;
    const localSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (!localSelectedLanguage) return;
    const localLanguageParsed = JSON.parse(localSelectedLanguage);
    const matchedLanguageOption =
      allLanguageOptions.find(
        (option) => option.id === localLanguageParsed.id
      ) || null;

    if (!matchedLanguageOption || !matchedLanguageOption.available) {
      return;
    }
    handleLanguageSelect(matchedLanguageOption);
  }, [handleLanguageSelect, allLanguageOptions, currentEvent]);

  const handleClose = () => onClose();

  return (
    <Modal
      open={open}
      disableAutoFocus
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          marginBottom="20px"
        >
          Select a language
        </Typography>
        {isDesktop ? (
          <Autocomplete
            value={
              allLanguageOptions.find((lang) => lang.id === selectedLanguage) ??
              allLanguageOptions.find((lang) => lang.available) ??
              null
            }
            fullWidth
            onChange={(event, newValue) => handleLanguageSelect(newValue)}
            options={allLanguageOptions}
            renderOption={(props, option) => {
              // Add a divider before the first unavailable option
              const index = allLanguageOptions.indexOf(option);
              const prevOption =
                index > 0 ? allLanguageOptions[index - 1] : null;
              const showDivider = prevOption?.available && !option.available;

              return (
                <React.Fragment key={option.id}>
                  {showDivider && (
                    <Box
                      component="li"
                      sx={{ borderTop: 1, borderColor: "divider" }}
                    />
                  )}
                  <Box component="li" {...props}>
                    {option.label}
                  </Box>
                </React.Fragment>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Language" />}
          />
        ) : (
          <Select
            labelId="language-select-label"
            value={selectedLanguage || "en-AU"}
            fullWidth
            onChange={(event) =>
              handleLanguageSelect(
                allLanguageOptions.find(
                  (lang) => lang.id === event.target.value
                ) ?? null
              )
            }
            displayEmpty
          >
            {allLanguageOptions.map((option, index) => {
              const prevOption =
                index > 0 ? allLanguageOptions[index - 1] : null;
              const showDivider = prevOption?.available && !option.available;

              return (
                <MenuItem
                  key={option.id}
                  sx={{
                    borderTop: showDivider ? 1 : "none",
                    borderColor: "divider",
                  }}
                  value={option.id}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity="info">
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
}
