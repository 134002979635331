import {
  Button,
  Dialog,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useUsageControlContext } from "../../../../context/UsageControl.tsx";

type Props = {
  open: boolean;
  onClose?: () => void;
};

export default function UsageLimitEnforcedModal({ open, onClose }: Props) {
  const { secondsLeft } = useUsageControlContext();
  const minutesLeft = Math.round(secondsLeft / 60);
  const theme = useTheme();

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <Paper
        elevation={3}
        sx={{
          height: "90vh",
          padding: "2em 8em",
          backgroundColor: "white",
        }}
      >
        <Stack direction="column" spacing={4} alignItems="center">
          <Typography
            textAlign="center"
            fontWeight={700}
            variant="h4"
            color="#333"
          >
            The{" "}
            <span style={{ color: theme.palette.primary.main }}>
              Sunflower AI
            </span>{" "}
            Interactive Demo
          </Typography>
          {minutesLeft === 30 ? (
            <Typography textAlign="center" variant="body1" color="#333">
              You will have <strong>30 minutes</strong> of free presentation
              time every 24 hours. Keep an eye on the countdown in the bottom
              left corner.
            </Typography>
          ) : (
            <Typography textAlign="center" variant="body1" color="#333">
              Thanks for returning, you have around{" "}
              <strong>{minutesLeft} minutes</strong> remaining of free
              presentation time.
            </Typography>
          )}
          {/* <Typography textAlign="center" variant="body1" color="#333">The Sunflower AI Interactive Demo is a free tool that allows you to experience the power of Sunflower AI's real-time transcription and translation services.</Typography> */}
          <div
            style={{ position: "relative", height: "300px", width: "420px" }}
          >
            <iframe
              src="https://www.loom.com/embed/f2ae0577616b455eb6272589d971889d?sid=0132b810-543d-42bc-8ed8-fbc4f0eb8a9f"
              title="Sunflower AI Interactive Demo"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
          <Button variant="contained" color="primary" onClick={onClose}>
            Try Now!
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
}
